




































import { Component, Vue, Watch } from "vue-property-decorator";
import { userUpdateBilling } from "@/api/users";

@Component({
  components: {}
})
export default class Profile extends Vue {
  formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8 }
  };
  formTailLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8, offset: 4 }
  };
  email = this.$store.state.auth.user.email;
  status = "";
  password = "";
  repeatPassword = "";
  disabledButton = true;
  loading = false;

  async update() {
    if (this.status === "success") {
      this.disabledButton = true;
      this.loading = true;
      await userUpdateBilling(this.password);
      this.openNotificationWithIcon();
      this.password = "";
      this.repeatPassword = "";
      this.status = "";
      this.loading = false;
    }
  }

  openNotificationWithIcon() {
    this.$notification.success({
      message: "Updated",
      description: "Your data has been updated"
    });
  }

  @Watch("repeatPassword")
  onRepeatPasswordChanged() {
    if (this.password !== "") {
      if (this.password === this.repeatPassword) {
        this.status = "success";
        this.disabledButton = false;
      } else {
        this.status = "error";
      }
    }
  }
}
