import ApiService from "@/api/api.service";
import { AxiosPromise } from "axios";
import { User } from "@/types/User";
import { PaginationResponse } from "@/types/PaginationResponse";

export const userUpdateBilling = (password: string): AxiosPromise => {
  return ApiService.post("/api/billing/user/update", {
    password
  });
};

export const loadUser = (id: number): AxiosPromise<User> => {
  return ApiService.get(`api/admin/users/${id}`);
};

export const loadUsers = (
  page: number,
  email: string
): AxiosPromise<PaginationResponse<User[]>> => {
  return ApiService.query("api/admin/users", {
    page,
    email
  });
};

export const updateUserStatus = (id: number, status: string): AxiosPromise<User> => {
  return ApiService.post(`api/admin/users/status/${status}/${id}`, {});
};
